<template lang="pug">
  v-dialog(v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel")
      v-toolbar(dark :color="options.color" dense flat)
        v-toolbar-title.text-body-1
          | {{ title }}
      v-card
        v-card-text.pa-4.text-body-2(v-show="!!message")
          | {{ message }}
        v-card-actions
          v-spacer
          v-btn.body-2.font-weight-bold(v-if="!options.noconfirm" color="grey" text @click.native="cancel")
            | {{ this.$t('confirmDialog.decline') }}
          v-btn.body-2.font-weight-bold(v-if="!options.noconfirm" color="primary" outlined @click.native="agree")
            | {{ this.$t('confirmDialog.accept') }}
</template>

<script>
export default {
  name: 'ConfirmDialog',
  data () {
    return {
      dialog: false,
      btnclose: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      options: {
        width: 'fit-content',
        zIndex: 200,
        noconfirm: false,
        color: 'primary'
      },
    }
  },
  watch: {
    dialog(open) {
      !open && !this.btnclose && this.resolve(false)
    }
  },  
  methods: {
    confirm(title, message, options) {
      this.dialog = true
      this.title = (title) ? title : this.$t('confirmDialog.title')
      this.message =  (message) ? message : this.$t('confirmDialog.confirm')
      this.options = Object.assign({
        width: 'fit-content',
        zIndex: 200,
        noconfirm: false,
        color: 'primary'
      }, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(true)
      this.btnclose = true
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.btnclose = true
      this.dialog = false
    },
  },
}
</script>

<style scoped>
</style>
